import React, { useState, useContext, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../../Auth';

import { ReactComponent as WifiIcon } from '../../../img/wifi.svg';
import { ReactComponent as UserIcon } from '../../../img/menu.svg';
import { ReactComponent as PhoneIcon } from '../../../img/call.svg';
import { ReactComponent as LocationIcon } from '../../../img/location.svg';
import { ReactComponent as InstagramIcon } from '../../../img/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../img/fb.svg';
import { ReactComponent as EmailIcon } from '../../../img/mail.svg';
import { ReactComponent as WhatsAppIcon } from '../../../img/whatsapp.svg';
import { ReactComponent as GoogleIcon } from '../../../img/google.svg';

import { ReactComponent as ShareIcon } from '../../../img/ios_share.svg';

import Block from '../../common/Block';
import Button from '../../common/Button';
import db from '../../../config/firebase';
import Icon from '../../common/Icon';
import ImageUpload from '../../common/ImageUpload';
import Input from '../../common/Input';
import LoadingSpinner from '../../common/Loadings/LoadingSpinner';
import ModalBottom from '../../common/ModalBottom';
import ModalFull from '../../common/ModalFull';
import Body02 from '../../common/typography/Body02';
import Body01 from '../../common/typography/Body01';

const SettingsButton = ({
    className
}) => {
    const { currentUser } = useContext(AuthContext);
    const { t } = useTranslation();

    const settings = useSelector(state => state.userSettings.userSettings);

    const [ open, setOpen ] = useState(false);
    const [ openName, setOpenName ] = useState(false);
    const [ openLogo, setOpenLogo ] = useState(false);
    const [ openIcons, setOpenIcons ] = useState(false);
    const [ image, setImage ] = useState(null);
    const [ progress, setProgress ] = useState(false);
    const [ name, setName ] = useState('');
    const [ openQRcode, setOpenQRcode ] = useState(false);

    const [ wf, setWifi ] = useState('');
    const [ phn, setPhone ] = useState('');
    const [ loc, setLocation ] = useState('');
    const [ insta, setInstagram ] = useState('');
    const [ fb, setFacebook ] = useState('');
    const [ eml, setEmail ] = useState('');
    const [ whtspp, setWhatsapp ] = useState('');
    const [ googleBId, setGoogleBusinessId ] = useState('');

    const theme = useTheme();

    useEffect(() => {
        setName(settings?.displayName);
    }, [ settings, openName ]);

    useEffect(() => {
        setImage(settings?.logoUrl);
    }, [ settings, openLogo ]);

    useEffect(() => {
        if (settings && settings.socials) {
            setWifi(settings.socials.wifi || '');
            setPhone(settings.socials.phone || '');
            setLocation(settings.socials.location || '');
            setInstagram(settings.socials.instagram || '');
            setFacebook(settings.socials.facebook || '');
            setEmail(settings.socials.email || '');
            setWhatsapp(settings.socials.whatsapp || '');
            setGoogleBusinessId(settings.socials.googleBusinessId || '');
        }
    }, [settings, openIcons]);

    const handleLogout = () => {
        setOpen(false);
        db.auth().signOut();
        localStorage.removeItem('activeCategory');
    };

    const docRef = db.firestore().collection("users").doc(currentUser.uid);

    const handleUpdateBusinessName = () => {
        docRef
			.set({
				displayName: name
			}, { merge: true })

        setOpenName(false);
    };

    const handleUpdateIcons = async () => {
        const socials = {
            wifi: wf || '',
            phone: phn || '',
            location: loc || '',
            instagram: insta || '',
            facebook: fb || '',
            email: eml || '',
            whatsapp: whtspp || '',
            googleBusinessId: googleBId || ''
        };

        try {
            await docRef.set({
                socials: socials,
            }, { merge: true });
            
            setOpenIcons(false); 
        } catch (error) {
            alert("Error updating icons, please try again later or contact us");
        }
    };

    const url = window.location.href;

    const downloadQR = () => {
        const canvas = document.getElementById("QRCode");
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QRCode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleLogoChange = (event) => {
        setImage(event.target.files[0]);
      }; 

    const handleLogoDelete = () => {
        setImage(null);
    };

    const handleLogoUpdate = async () => {

        setProgress(true);

        const storageRef = db.storage().ref(`images/${ currentUser.uid }/logo`);

        const imageRef = image && storageRef.child(image.name);

        let url = '';

        if (image) {
            try { 
                await imageRef.put(image)
                .then(() => imageRef.getDownloadURL())
                .then((img) => url = img)
            } 
            catch(error) { alert(error) }
        } 

        const docRef = db.firestore().collection("users").doc(currentUser.uid);

		docRef
		.set({
			logoUrl: url
		}, { merge: true });

        setOpenLogo(false);
        setProgress(false);
    };

    const handleShareQRcode = () => {
        if (navigator.share) {
            navigator
              .share({
                title: 'Reaction menu',
                text: `Digital menu of ${ settings.businessName }`,
                url: document.location.href
              })
              .catch(error => {
                alert('Something went wrong sharing the menu, please try again or contact us');
              });
          }
    };

    const socialInputs = [
        { value: wf, setter: setWifi, placeholder: 'beauty_is_in_simplicity', label: 'Add WiFi password', id: 'WiFi', icon: <WifiIcon /> },
        { value: insta, setter: setInstagram, placeholder: 'https://www.instagram.com/...', label: 'Add Instagram', id: 'Instagram', icon: <InstagramIcon /> },
        { value: fb, setter: setFacebook, placeholder: 'https://www.facebook.com/...', label: 'Add Facebook', id: 'Facebook', icon: <FacebookIcon /> },
        { value: whtspp, setter: setWhatsapp, placeholder: '+357...', label: 'Add Whatsapp', id: 'Whatsapp', icon: <WhatsAppIcon /> },
        { value: eml, setter: setEmail, placeholder: 'contact@you.com', label: 'Add Email', id: 'Email', icon: <EmailIcon /> },
        { value: loc, setter: setLocation, placeholder: 'https://maps.app.goo.gl/...', label: 'Add location', id: 'Location', icon: <LocationIcon /> },
        { value: phn, setter: setPhone, placeholder: '+357...', label: 'Add phone', id: 'Phone number', icon: <PhoneIcon /> },
        { value: googleBId, setter: setGoogleBusinessId, placeholder: 'ChIJubJqVQ8z5xQRtVeYvYSCkE8', label: 'Google place ID', id: 'Google plcae ID', icon: <GoogleIcon /> },
    ];

    const renderSocialInput = ({ value, label, id, setter, placeholder, icon }) => (
        <Row key={id}>
            <Input id={id}
                label={label}
                value={value}
                onChange={(e) => setter(e.target.value)}
                placeholder={placeholder}
                clearable
                onClear={() => setter('')}
            />
            
            <Icon icon={ icon } color={ value === googleBId ? '' : theme.text } size={ 24 } />
        </Row>
    );

    return (
        <Root className={ className }>
            <Icon onClick={ () => setOpen(true) } 
                icon={ <UserIcon /> } 
                color={ theme.body }
            />

            <ModalBottom open={ open }
                onClose={ () => setOpen(false) }
                title={ t('Settings') }
            >
                    <Block center
                        onClick={ () => (setOpenLogo(true), setOpen(false)) }
                    >
                        <Body01>{ t('Your logo') }</Body01>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenName(true), setOpen(false)) }
                    >
                        <Body01>{ t('Business name') }</Body01>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenIcons(true), setOpen(false)) }
                    >
                        <Body01>{ t('Social links') }</Body01>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenQRcode(true), setOpen(false))  }
                    >
                        <Body01>{ t('Menu QR code') }</Body01>
                    </Block>

                    <Block onClick={ handleLogout }
                        center
                    >
                        <Body01>{ t('Logout') }</Body01>
                    </Block>
            </ModalBottom>

            <ModalFull onClose={ () => setOpenQRcode(false) }
                title="QR code"
                open={ openQRcode }
                onSave={ handleShareQRcode }
                label={ <Icon icon={ <ShareIcon /> } /> }
                noPaddingRight
            >
                <Block center noBorder>
                    <Body02 color={ theme.textSecondary }> 
                        Print and place this QR code on tables, walls, paper menus - guests will scan it with their smartphone camera to open the menu.</Body02>
                 </Block>

                 <Block center noBorder>
                    <StyledQRCode id="QRCode"
                        value={ url }
                        size={ 200 }
                    /> 
                 </Block>
                
                 <Block center noBorder>
                    <Button primary 
                        onClick={ downloadQR } 
                        label={ t("Download") } 
                        width="100%"
                    />
                </Block>
            </ModalFull> 

            <ModalFull onClose={ () => setOpenName(false) }
                title={ t('Business name') }
                open={ openName }
                onSave={ handleUpdateBusinessName }
                disabled={ name === settings.businessName }
            >
                <ModalContent>
                    <Input value={ name }
                        onChange={ (e) => setName(e.target.value) }
                        placeholder={ t('Business name') }
                        autoFocus
                    />
                </ModalContent>
            </ModalFull> 
            
            <ModalFull onClose={ () => setOpenLogo(false) }
                title= { t('Set logo') }
                open={ openLogo }
                onSave={ handleLogoUpdate }
                label={ progress ? <LoadingSpinner color={ theme.text } size="24px" /> : t("Save") }
            >
                <Block center noBorder>
                    <Body02 color={ theme.textSecondary }> 
                        Supported formats: svg, png or jpg
                    </Body02>
                </Block>

                <ImageUpload userId={ currentUser.uid } 
                    onImageChange={ handleLogoChange }
                    image={ image }
                    onDelete={ handleLogoDelete }
                    deleteLabel={ t("Delete logo") }
                    rounded
                />
            </ModalFull>


            <ModalFull onClose={ () => setOpenIcons(false) }
                title={ t('Social links') }
                open={ openIcons }
                onSave={ handleUpdateIcons }
            >
                <ModalContent>
                    <StyledBody02 color={ theme.textSecondary }>
                        Link out to your online profiles. 
                        If you need any help or there is a platform that you would like us to add, please <a href="https://reaction.menu#contacts" target="blank">reach out</a>
                    </StyledBody02>
                    
                    {socialInputs.map(renderSocialInput)}

                    <Body02>
                        <a href="https://developers.google.com/maps/documentation/places/web-service/place-id" target="_blanc">Find my Google place ID</a>
                    </Body02>
                </ModalContent>
            </ModalFull> 
        </Root>
    );
}

export default SettingsButton;

const Root = styled.div`
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;

const StyledBody02 = styled(Body02)`
    margin-bottom: 16px
`;

const ModalContent = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;


const StyledQRCode = styled(QRCode)`
  
`;


