import styled from 'styled-components';
import React from 'react';

const IconButton = ({
    className,
    label,
    onClick,
    icon,
    width
}) => {

    return (
        <Root className={ className }
            onClick={ onClick }
            width={width}
        >
            <Icon>{ icon }</Icon>

            { label }
        </Root>
    );
}

export default IconButton;

const Root = styled.button`
    align-items: center;
    background: transparent;
    border-radius: 8px;
    border: 1px solid ${ ({ theme }) => theme.border };
    color: ${ ({ theme }) => theme.text };
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: bold, Arial;
    font-size: 12px;
    justify-content: center;
    letter-spacing: 0;
    margin: 0;
    outline: none;
    padding: 12px 8px;
    position: relative;
    width: ${ ({ width }) => width ? width : 'auto'};

    &:active {
        opacity: 0.9;
    }

    &:hover {
        opacity: 0.9;
    }
`;

const Icon = styled.div`
    svg{
        width: 20px;  
        fill: ${ ({ theme }) => theme.text };
    }
`;
