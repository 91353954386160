import chroma from 'chroma-js';

export const color = {
    red: '#ff1744',
    grey: {
        grey0: '#fafafa',
        grey1: '#eceff1',
        grey2: '#cfd8dc',
        grey3: '#b0bec5',
        grey4: '#90a4ae',
        grey5: '#78909c',
        grey6: '#607d8b',
        grey7: '#546e7a',
        grey8: '#455a64',
        grey9: '#37474f',
        grey10: '#000000',
    },
    green: {
        green0: '#D6FFED',
        green10: '#00A35C'
    },
    white: '#ffffff'
}

export const theme = {
    green: '#2FC26E',
    pink: '#F292B8',
    purple: '#838EFF',
    body: color.white,
    shadow: `0 0 16px 0 ${ chroma(color.grey.grey10).alpha(0.1) }`,

    text: color.grey.grey10,
    textSecondary: color.grey.grey8,

    brighten: chroma(color.grey.grey10).brighten(),

    border: color.grey.grey2,
    disabled: color.grey.grey3,
    placeholder: color.grey.grey5,
    overlay: chroma(color.grey.grey10).alpha(0.7),

    red: color.red,

    transition: 'all 300ms cubic-bezier(0.465, 0.183, 0.153, 0.946)',
    tabletBreakpoint: '767px'
}
