import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase';
import styled from 'styled-components';

import { AuthContext } from '../../Auth';
import db from '../../config/firebase';
import Input from '../common/Input';
import ModalFull from '../common/ModalFull';

const AddCategory = ({
    onClose,
    open
}) => {

    const { t } = useTranslation();

    const { currentUser } = useContext(AuthContext);

    const [ enCategoryName, setEnCategoryName ] = useState('');
    const [ elCategoryName, setElCategoryName ] = useState('');
    const [ ruCategoryName, setRuCategoryName ] = useState('');

    const addCategory = async (e) => {
        e.preventDefault();    

        const docRef = db.firestore().collection("users").doc(currentUser.uid);

        try {
            const newCategory = {
                id: uuidv4(),
                translated: {
                    en: enCategoryName.trim(),
                    el: elCategoryName === '' ? enCategoryName.trim() : elCategoryName.trim(),
                    ru: ruCategoryName === '' ? enCategoryName.trim() : ruCategoryName.trim(),
                },
                hidden: false,
                timestamp: firebase.firestore.Timestamp.now()
            };

            await docRef.update({
                categories: firebase.firestore.FieldValue.arrayUnion(newCategory),
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });

            onClose();
            setEnCategoryName('');
            setElCategoryName('');
            setRuCategoryName('');

        } catch (error) {
            alert('Error adding category, please try again or contact us', error);
        }
    };

    const handleClose = () => {
        onClose();
        setEnCategoryName('');
        setElCategoryName('');
        setRuCategoryName('');
    };

    return (
        <ModalFull title={ t("New category") }
            disabled={ !enCategoryName }
            onClose={ handleClose  }
            onSave={ addCategory }
            open={ open }
        >
            <Root>
                <Input id="enCategoryName" 
                    label='English'
                    value={ enCategoryName }
                    onChange={ (e) => setEnCategoryName(e.target.value) }
                    autoFocus
                    required
                 />

                <Input id="elCategoryName" 
                    label='Ελληνικά'
                    value={ elCategoryName }
                    onChange={ (e) => setElCategoryName(e.target.value) }
                 />            

                <Input id="ruCategoryName" 
                    label='Русский'
                    value={ ruCategoryName }
                    onChange={ (e) => setRuCategoryName(e.target.value) }
                 />
            </Root>
        </ModalFull>
    )
}
export default AddCategory;

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px;
`;