import React, { useState, useContext, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase';

import { addToCart } from '../cart/cartSlice';
import { AuthContext } from '../../../Auth';
import { Inputs, StyledCaption, Elements, Element } from '../AddNewItem';
import { resizeImage } from '../../common/ImageUpload';
import Block from '../../common/Block';
import Body01 from '../../common/typography/Body01';
import Body02 from '../../common/typography/Body02';
import Button from '../../common/Button';
import Chip from '../../common/Chip';
import db from '../../../config/firebase';
import Headline06 from '../../common/typography/Headline06';
import ImageUpload from '../../common/ImageUpload';
import Input from '../../common/Input';
import LoadingSpinner from '../../common/Loadings/LoadingSpinner';
import ModalBottom from '../../common/ModalBottom';
import ModalFull from '../../common/ModalFull';
import Tag from '../../common/Tag';
import Text from '../../common/Text';
import Textarea from '../../common/Textarea';
import Toggle from '../../common/Toggle';

const Item = ({
    item,
    hiddenCategory,
    userId,
    lang
}) => {
    const { t } = useTranslation();

    const itemsInCart = useSelector(state => state.cart.itemsInCart);
    const items = useSelector(state => state.items.items);

    const dispatch = useDispatch();

    const theme = useTheme();

    const [ enItemName, setEnItemName ] = useState(item.translated.name.en || '');
    const [ elItemName, setElItemName ] = useState(item.translated.name.el|| '');
    const [ ruItemName, setRuItemName ] = useState(item.translated.name.ru || '');

    const [ enItemDescription, setEnItemDescription ] = useState(item.translated.description.en || '');
    const [ elItemDescription, setElItemDescription ] = useState(item.translated.description.el || '');
    const [ ruItemDescription, setRuItemDescription ] = useState(item.translated.description.ru || '');

    const [ price, setPrice ] = useState(item.price || '')
    const [ openItem, setOpenItem ] = useState(false);
    const [ openEdit, setOpenEdit ] = useState(false);
    const [ openHide, setOpenHide ] = useState(false);
    const [ openDelete, setOpenDelete ] = useState(false);
    const [ vegetarian, setVegetarian ] = useState(item.vegetarian || false);
    const [ vegan, setVegan ] = useState(item.vegan || false);
    const [ image, setImage ] = useState(item.imageUrl || null);
    const [ progress, setProgress ] = useState(false);
    const [ imageIsUploading, setImageIsUploading ] = useState(false);
    const [ openEditPosition, setOpenEditPosition ] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const filteredItems = items.filter(filteredItem => filteredItem.category === item.category && filteredItem.id !== item.id);

    useEffect(() => {
        if (openEdit) {
            setEnItemName(item.translated.name.en || '');
            setElItemName(item.translated.name.el || '');
            setRuItemName(item.translated.name.ru || '');

            setEnItemDescription(item.translated.description.en || '');
            setElItemDescription(item.translated.description.el || '');
            setRuItemDescription(item.translated.description.ru || '');

            setPrice(item.price || '');
            setVegetarian(item.vegetarian || false);
            setVegan(item.vegan || false);
            setImage(item.imageUrl || null);
        }
    }, [ openEdit ]);

    const user = currentUser && currentUser.uid === userId;

    const docRef = user && db.firestore()
    .collection('users')
    .doc(currentUser.uid);

    const changePosition = async (stamp) => {
        try {  
            const timestampCopy = new Date(stamp.toDate());
            timestampCopy.setSeconds(timestampCopy.getSeconds() - 1);
            const updatedTimestamp = firebase.firestore.Timestamp.fromDate(timestampCopy);
            
           const updatedItems = items.map(itm => {
                if (itm.id === item.id) {
                    return {
                        ...itm,
                        timestamp: updatedTimestamp
                    };
                }
                return itm;
            });
            
            await docRef.update({ 
                items: updatedItems,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });
            setOpenEditPosition(false);
        } catch (error) {
            alert('Error changing position of the item, please try again later or contact us');
        }
    };

    const hideItem = async () => {
        try {   
            const updatedItems = items.map(it => {
                if (it.id === item.id) {
                    return {
                        ...it,
                        hidden: !it.hidden
                    };
                }
                return it;
            });
            
            await docRef.update({ 
                items: updatedItems,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });
            setOpenHide(false);
        } catch (error) {
            alert('Error changing visibility of the item, please try again later or contact us');
        }
    };

    const deleteItem = async () => {
        try {                
            await docRef.update({
                items: firebase.firestore.FieldValue.arrayRemove(item),
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });

        } catch (error) {
            alert('Error removing item, please try again or contact us');
        }
        setOpenDelete(false);
    };

    const handleImageChange = async (event) => {
        setImageIsUploading(true)
        try {
            const resizedImage = await resizeImage(event.target.files[0]);
            setImage(resizedImage);
            setImageIsUploading(false);
        } catch (err) {
          console.log(err);
        }
      }; 

    const handleImageDelete = () => {
        setImage(null);
    };

    const updateItem = async () => {
        setProgress(true);

        const storageRef = db.storage().ref(`images/${ currentUser.uid }/`);

        const imageRef = image && storageRef.child(`${ item.id }.JPEG`);

        let url = '';

        if (image && image === item.imageUrl) {
            url = item.imageUrl;
        }

        else if (!image && item.imageUrl) {
            const ref = storageRef.child(`${ item.id }.JPEG`);

            ref.delete();
            url = null;
        }

        else if (!image) {
            url = null;
        }

        else {
            try {
                await imageRef.put(image);
                url = await imageRef.getDownloadURL(); // Get the new image URL
            } catch (error) {
                alert('Error uploading image, please try again later or contact us');
                setProgress(false);
                return; 
            }
        }
           
        const updatedItems = items.map(itm => {
            if (itm.id === item.id) {
                return {
                        ...itm,
                        translated: {
                            name: {
                                en: enItemName,
                                el: elItemName,
                                ru: ruItemName
                            },
                            description: {
                                en: enItemDescription,
                                el: elItemDescription,
                                ru: ruItemDescription
                            }
                        },
                            imageUrl: url,
                            price: price.trim(),
                            vegetarian: vegetarian,
                            vegan: vegan
                    }
                }
            return itm;
        });
    

        try {
        await docRef.update({ 
            items: updatedItems,
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });
            setOpenEdit(false);
            setProgress(false);

        } catch (error) {
            alert('Error updating item, please try again later or contact us');
        }
    };




    if ((item.hidden === true || hiddenCategory) && currentUser?.uid !== userId) {
        return null;
    }

    const handlePriceClick = (e) => (
        e.stopPropagation(),
        dispatch(addToCart(item.id))
    );

    const RenderContent = ({ full }) => {
        const itemName =  item.translated?.name ? item.translated.name[lang] : '';
        const descriptionName = item.translated?.description ? item.translated.description[lang] : '';
  
        return (
        <Content full={ full }>
            { full ?
                <StyledHeadline06 bold>
                    { itemName }
                </StyledHeadline06>
                : <Body01 bold>{ itemName }</Body01>
            }

            { 
                full ? 
                    <DescriptionBig>{ descriptionName }</DescriptionBig> 
                    : <DescriptionSmall color={ theme.textSecondary }>{ descriptionName }</DescriptionSmall>
            }
            
            <Tags>  
                { item?.vegan && <StyledTag label={ t("Vegan") } /> }

                { item?.vegetarian && <StyledTag label={ t("Vegetarian") } /> }
            </Tags>

            { !full && 
                <StyledChip onClick={ handlePriceClick }
                    active={ itemsInCart.includes(item.id) }
                    withCheckmark
                >
                    € { Number(item.price).toFixed(2) }
                </StyledChip>
            }

            { full && <StyledButton primary
                remove={ itemsInCart.includes(item.id) }
                label={ itemsInCart.includes(item.id) ? `${ t("Remove") }  - € ${ Number(item.price).toFixed(2) }` : `${ t("Add") }  - € ${ Number(item.price).toFixed(2) }` } 
                onClick={ () => dispatch(addToCart(item.id), setOpenItem(false)) }
                width="100%"  

            /> }

        </Content>
)};

    return (
        <>
            <Root onClick={ () => setOpenItem(true) }
                hidden={ !!item.hidden || hiddenCategory }
            >
                <RenderContent />

                { item.imageUrl && <ImgContainer url={ item.imageUrl } /> }
            </Root>

            <ModalBottom onClose={ () => setOpenItem(false) }
                open={ openItem }
                close
            >
                <FullImgContainer>
                    { item.imageUrl && 
                        <img src={ item.imageUrl } 
                            alt="reaction menu"
                        /> 
                    }
                </FullImgContainer>

                <RenderContent full />

                { currentUser && currentUser.uid === userId &&
                    <Border>
                        <Block center
                            onClick={ () => (setOpenEdit(true), setOpenItem(false))  }
                        >
                            <Text>{ t("Edit") }</Text>
                        </Block>

                        <Block center
                            onClick={ () => (setOpenEditPosition(true), setOpenItem(false))  }
                        >
                            <Text>{ t("Move item") } </Text>  
                        </Block>

                        <Block center
                            onClick={ () => (setOpenHide(true), setOpenItem(false))  }
                        >
                            <Text>{ item.hidden ? t("Make visible") : t("Hide") } </Text>  
                        </Block>

                        <Block center
                            onClick={ () => (setOpenDelete(true), setOpenItem(false))  }
                        >
                            <Text red>{ t("Delete") }</Text>
                        </Block>
                    </Border>
                }
            </ModalBottom>

            <ModalFull onClose={ () => setOpenEdit(false) }
                disabled={ !enItemName || !price }
                title={ t("Edit item") }
                onSave={ updateItem }
                open={ openEdit }
                label={ progress ? <LoadingSpinner color={ theme.text } size="24px" /> : t("Save") }
            >
                 <Elements>
                <Element>
                    <ImageUpload onImageChange={ handleImageChange }
                        image={ image }
                        onDelete={ handleImageDelete }
                        inProgress={ imageIsUploading }
                    />
                </Element>
                
                <Element>
                    <StyledCaption required>{ t("Title") }</StyledCaption>

                    <Inputs>
                         <Input id='enItemName'
                            label='English'
                            value={ enItemName }
                            onChange={ (e) => setEnItemName(e.target.value) }
                            required
                        />

                        <Input id='elItemName'
                            label='Ελληνικά'
                            value={ elItemName }
                            onChange={ (e) => setElItemName(e.target.value) }
                        />            

                        <Input id='ruItemName'
                            label='Русский'
                            value={ ruItemName }
                            onChange={ (e) => setRuItemName(e.target.value) }
                        />
                    </Inputs>
                </Element>

                <Element>
                    <StyledCaption required>{ t("Price") }</StyledCaption>

                    <Inputs>
                        <Input value={ price }
                            onChange={ (e) => setPrice(e.target.value) }
                            placeholder="0.00"
                            type="number"
                            step="0.01"
                            required
                        />
                    </Inputs>
                </Element>
                        
                <Element>
                    <StyledCaption>{ t("Description") }</StyledCaption>

                    <Inputs>
                        <Textarea value={ enItemDescription }
                            onChange={ (e) => setEnItemDescription(e.target.value) }
                            placeholder='English'
                        />

                        <Textarea value={ elItemDescription }
                            onChange={ (e) => setElItemDescription(e.target.value) }
                            placeholder='Ελληνικά'
                        />

                        <Textarea value={ ruItemDescription }
                            onChange={ (e) => setRuItemDescription(e.target.value) }
                            placeholder='Русский'
                        />
                    </Inputs>
                </Element>
            </Elements>

                <Block>
                    <Text bold>{ t("Vegetarian") }</Text>

                    <Toggle checked={ vegetarian }
                        onChange={ () => setVegetarian(!vegetarian) }
                    />
                </Block>

                <Block>
                    <Text bold>{ t("Vegan") }</Text>

                    <Toggle checked={ vegan }
                        onChange={ () => setVegan(!vegan) }
                    />
                </Block>
            </ModalFull> 

            <ModalBottom onClose={ () => setOpenEditPosition(false) }
                title={ t('Put before...') }
                open={ openEditPosition }
            >
                { filteredItems && filteredItems.map(item => (
                    <Block onClick={ () => changePosition(item.timestamp) }
                        key={ item.id }
                    >
                        <Body01>
                            {  item?.translated?.name ? item.translated.name[lang] : '' }
                        </Body01>
                    </Block>
                ))}
            </ModalBottom>

            <ModalBottom onClose={ () => setOpenHide(false) }
                title={ item.hidden ? t('Make item visible?') : t('Hide item?') }
                open={ openHide }
            >
                <Block center noBorder>
                    <Text small center>The item will be { item.hidden === true ? 'visible' : 'invisible'} to the customers</Text>
                </Block>

                <Block center noBorder>
                    <Button label={ item.hidden ? t('Make visible') : t('Hide')}
                        primary 
                        onClick={ hideItem }
                        width="100%"
                    />
                </Block>
            </ModalBottom>

            <ModalBottom onClose={ () => setOpenDelete(false) }
                title={ t('Delete item?') }
                open={ openDelete }
            >
                <Block center noBorder>
                    <DeleteButton onClick={ deleteItem }
                        label={ t("Delete") }
                        primary
                        width="100%"
                    />
                </Block>
            </ModalBottom>
        </>
    )
}

export default Item;

const Border = styled.div`
    border-top: 1px solid ${ ({ theme }) => theme.border }
`;

const StyledButton = styled(Button)`
    background-color: ${ ({ theme, remove }) => remove && theme.red };
    margin-top: 16px;
`;

const DeleteButton = styled(Button)`
    background-color: ${ ({ theme }) => theme.red }
`;

const Root = styled.div`
    border-bottom: 1px solid ${ ({ theme }) => theme.border };
    display: flex;
    justify-content: space-between;
    margin: 0 20px 0;
    padding: 16px 0;
    opacity: ${ ({ hidden }) => hidden ? '0.3' : 1 };
`;

const Content = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: ${ ({ full }) => full ? '16px': '0 12px 0 0' };
    min-width: 0;
    position: relative;
`;

const ImgContainer = styled.div`
    background-image: url(${ ({ url }) => url });
    background-position:center center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    width: 30%;
    padding-bottom: 30%;
    border-radius: 8px;
`;

const FullImgContainer = styled.div`
   img {
       border-radius: 8px 8px 0 0;
   }
`;

const StyledChip = styled(Chip)`
    margin-top: 16px;
`;

const Tags = styled.div`
    display: flex;
    align-items: flex-start;
`;

const StyledTag = styled(Tag)`
    margin-top: 12px;
`;

const StyledHeadline06 = styled(Headline06)` 
    margin-right: 64px;
`;

const DescriptionSmall = styled(Body02)`
    margin-top: 8px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DescriptionBig = styled(Body01)`
    margin-top: 8px;
`;