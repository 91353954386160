import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';

import Alert from '../../common/Alert';
import ModalBottom from '../../common/ModalBottom';
import Headline06 from '../../common/typography/Headline06';
import IconButton from '../../common/IconButton';

import { ReactComponent as ShareIcon } from '../../../img/ios_share.svg';
import { ReactComponent as DownloadIcon } from '../../../img/download.svg';
import { ReactComponent as CopyIcon } from '../../../img/copy.svg';

const Share = ({
    className
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const settings = useSelector(state => state.userSettings.userSettings);
    const url = window.location.href;

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 3000); // 3000 milliseconds = 3 seconds

            return () => clearTimeout(timer); // Cleanup the timer on unmount
        }
    }, [showAlert]);

    const handleDownloadQR = () => {
        const canvas = document.getElementById("QRCode");
        if (!canvas) {
            setAlertMessage('QR Code not found!');
            setShowAlert(true);
            return; 
        }
    
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QRCode.png";
        document.body.appendChild(downloadLink);
    
        try {
            downloadLink.click();
            setAlertMessage('Saved!'); 
        } catch (error) {
            setAlertMessage('Failed to download QR Code. Please try again.'); 
        } finally {
            document.body.removeChild(downloadLink); 
            setShowAlert(true); 
        }
    };

    const handleCopyQR = () => {
        navigator.clipboard.writeText(url) 
            .then(() => {
                setAlertMessage('Link copied to clipboard!');
                setShowAlert(true); 
            })
            .catch(error => {
                alert('Failed to copy the link, plese try again or contact us'); 
            });
    };

    const handleShareQR = () => {
        if (navigator.share) {
            navigator
              .share({
                title: 'Reaction menu',
                text: `Digital menu of ${settings?.displayName}`,
                url: document.location.href
              })
              .catch(error => {
                alert('Something went wrong sharing the menu, please try again or contact us');
              });
          }
    };

    return (
        <Root className={ className }>
            <Icon onClick={ () => setOpen(true) }>
                <ShareIcon />
            </Icon>

            <ModalBottom open={ open }
                onClose={ () => setOpen(false) }
                title={ t("Share menu") } 
            >
                <ModalContent>
                    <Headline06 center bold>
                        {settings?.displayName}
                    </Headline06>

                    <QrDiv>
                        <QRCode id="QRCode"
                            value={ window.location.href }
                            size={ 264 }
                        /> 
                    </QrDiv>
                
                    <Icons>
                    <IconButton onClick={ handleCopyQR } 
                        label={ t("Copy link") } 
                        icon={ <CopyIcon /> }
                        width={ '88px'}
                    />

                    <IconButton onClick={ handleDownloadQR } 
                        label={ t("Download") } 
                        icon={ <DownloadIcon /> }
                        width={ '88px'}
                    />

                    <IconButton onClick={handleShareQR } 
                        label={ t("Share menu") } 
                        icon={ <ShareIcon /> }
                        width={ '88px'}
                    />
                    </Icons>

                    {showAlert && 
                        <AlertWrapper>
                            <Alert message={alertMessage} 
                                onClose={() => setShowAlert(false)} 
                                width={'280px'}
                            />  
                        </AlertWrapper>
                    }
                </ModalContent>
            </ModalBottom>
        </Root>
    );
}

export default Share;


const Root = styled.div`

`;

const AlertWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Icons = styled.div`
    display: flex;
    gap: 8px;
    margin: 8px auto 16px;
    justify-content: center;
`;

const QrDiv = styled.div`
    margin: 16px auto;
    display: flex;
    justify-content: center;
    width: 100%;
`;

const ModalContent = styled.div`
    padding: 24px 16px;
    width: 100%;
`;

const Icon = styled.div`
    align-items: center;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;
    border-radius: 50%;
    border: 1px solid ${ ({ theme }) => theme.border };

    svg {
    width: 20px;
    height: 20px;
    fill: ${ ({ theme }) => theme.text };

    }
`;