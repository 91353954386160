import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

import Body02 from './typography/Body02';

const Alert = ({ message, width }) => {
    return (
        <AlertContainer width={ width }>
            <Body02 center>{message}</Body02>
        </AlertContainer>
    );
};

export default Alert;

const AlertContainer = styled.div`
    background: ${ ({ theme }) => chroma(theme.green).alpha(0.2) };
    color: ${ ({ theme }) => theme.green };
    padding: 16px;
    border-radius: 8px;
    z-index: 1000;
    width: ${ ({ width }) => width ? width : '100%' };

`;