import React, { useRef } from 'react';
import styled from 'styled-components';

import { useAutoTextAreaSize } from '../../hooks/useAutoTextAreaSize';

const Textarea = ({
    value,
    onChange,
    placeholder
}) => {
    const textAreaRef = useRef(null);

    useAutoTextAreaSize(textAreaRef);

    return (
        <Root value={ value }
            onChange={ onChange }
            placeholder={ placeholder }
            ref={ textAreaRef }
            rows={ 3 }
            maxLength={ 1000 }
        />
    )};

export default Textarea;

const Root = styled.textarea`
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid ${ ({ theme }) => theme.border };
    color: ${ ({ theme }) => theme.text };
    font-family: regular;
    font-size: 18px;
    line-height: 24px;
    outline: none;
    padding: 0;
    width: 100%;
    resize: none;
    padding: 12px 16px;

    ::placeholder {
        color: ${ ({ theme }) => theme.placeholder };
    }
`;
