import React, { useState, useContext, useEffect } from 'react';
import firebase from 'firebase';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { AuthContext } from '../../Auth';
import { resizeImage } from '../common/ImageUpload';
import Block from '../common/Block';
import CategoriesList from './categories/CategoriesList';
import db from '../../config/firebase';
import ImageUpload from '../common/ImageUpload';
import Input from '../common/Input';
import LoadingSpinner from '../common/Loadings/LoadingSpinner';
import ModalFull from '../common/ModalFull';
import Text from '../common/Text';
import Textarea from '../common/Textarea';
import Toggle from '../common/Toggle';
import Caption from '../common/Caption';

const AddNewItem = ({
    categories,
    onClose,
    fixedCategory,
    open,
    lang
}) => {
    const { t } = useTranslation();

    const theme = useTheme();

    const [ enItemName, setEnItemName ] = useState('');
    const [ elItemName, setElItemName ] = useState('');
    const [ ruItemName, setRuItemName ] = useState('');

    const [ enItemDescription, setEnItemDescription ] = useState('');
    const [ elItemDescription, setElItemDescription ] = useState('');
    const [ ruItemDescription, setRuItemDescription ] = useState('');

    const [ price, setPrice ] = useState('');

    const [ activeCategory, setActiveCategory ] = useState(() => {
        const saved = localStorage.getItem("activeCategory");
        const localStorageValue = JSON.parse(saved);
        return localStorageValue || categories[0] || "";
    });

    const [ vegan, setVegan ] = useState(false);
    const [ vegetarian, setVegetarian ] = useState(false);

    const [ image, setImage ] = useState(null);
    const [ progress, setProgress ] = useState(false);
    const [ imageIsUploading, setImageIsUploading ] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const storageRef = db.storage().ref(`images/${ currentUser?.uid }/`);
    
    const handleImageChange = async (event) => {
        setImageIsUploading(true)
        try {
            const resizedImage = await resizeImage(event.target.files[0]);
            setImage(resizedImage);
            setImageIsUploading(false);
        } catch (err) {
          console.log(err);
        }
      };

      const handleImageDelete = async () => {
        setImage(null);
    };


    const addItem = async (e) => {
        e.preventDefault();    
        setProgress(true);

        const imageRef = image && storageRef.child(image.name);

        let url = '';

        if (image) {
            try { 
                await imageRef.put(image)
                .then(() => imageRef.getDownloadURL())
                .then((img) => url = img)
            } 
            catch(error) { alert(error) }
        } 
        const docRef = db.firestore().collection("users").doc(currentUser.uid);

        try {
            const newItem = {
                id: uuidv4(),
                category: fixedCategory ? fixedCategory.id : activeCategory.id,
                translated: {
                    name: {
                        en: enItemName.trim(),
                        el: elItemName === '' ? enItemName.trim() : elItemName.trim(),
                        ru: ruItemName === '' ? enItemName.trim() : ruItemName.trim(),
                    },
                    description: {
                        en: enItemDescription.trim(),
                        el: elItemDescription === '' ? enItemDescription.trim() : elItemDescription.trim(),
                        ru: ruItemDescription === '' ? enItemDescription.trim() : ruItemDescription.trim(),
                    }
                },
                hidden: false,
                timestamp: firebase.firestore.Timestamp.now(),
                imageUrl: url,
                price: price.trim(),
                vegan:vegan,
                vegetarian: vegetarian
            };

            await docRef.update({
                items: firebase.firestore.FieldValue.arrayUnion(newItem),
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });

            setProgress(false);

            onClose();

        } catch (error) {
            console.log('Error adding item, please try again or contact us');
        }
    };

    useEffect(() => {
        if (open) {
            setEnItemName('');
            setElItemName('');
            setRuItemName('');

            setEnItemDescription('');
            setElItemDescription('');
            setRuItemDescription('');

            setPrice('');
            setVegan(false);
            setVegetarian(false);
            setImage(null);
            setActiveCategory(() => {
                const saved = localStorage.getItem("activeCategory");
                const localStorageValue = JSON.parse(saved);
                return localStorageValue || categories[0] || "";
            })}
        },[ open ])

    useEffect(() => {
        localStorage.setItem("activeCategory", JSON.stringify(activeCategory));
      }, [ activeCategory ]);

    return (
        <ModalFull disabled={ !enItemName || !price || !activeCategory }
            title={ t("New item") }
            onClose={ onClose  }
            onSave={ addItem }
            open={ open }
            label={ progress ? <LoadingSpinner color={ theme.text } size="24px" /> : t("Save") }
        >
            <Elements>
                <Element>
                    <ImageUpload onImageChange={ handleImageChange }
                        image={ image }
                        onDelete={ handleImageDelete }
                        inProgress={ imageIsUploading }
                    />
                </Element>
                
                <Element>
                    <StyledCaption required>{ t("Category") }</StyledCaption>

                        { (fixedCategory && fixedCategory.translated) ? 
                            <Text>{ fixedCategory.translated[lang] }</Text>
                            : <CategoriesList categories={ categories }
                            activeCategory={ activeCategory.id }
                            onCategoryChange={ (category) => setActiveCategory(category) }
                            lang={ lang }
                        />
                        }
                </Element>
                
                <Element>
                    <StyledCaption required>{ t("Title") }</StyledCaption>

                    <Inputs>
                         <Input id='enItemName'
                            label='English'
                            value={ enItemName }
                            onChange={ (e) => setEnItemName(e.target.value) }
                            required
                        />

                        <Input id='elItemName'
                            label='Ελληνικά'
                            value={ elItemName }
                            onChange={ (e) => setElItemName(e.target.value) }
                        />            

                        <Input id='ruItemName'
                            label='Русский'
                            value={ ruItemName }
                            onChange={ (e) => setRuItemName(e.target.value) }
                        />
                    </Inputs>
                </Element>

                <Element>
                    <StyledCaption required>{ t("Price") }</StyledCaption>

                    <Inputs>
                        <Input value={ price }
                            onChange={ (e) => setPrice(e.target.value) }
                            placeholder="0.00"
                            type="number"
                            step="0.01"
                            required
                        />
                    </Inputs>
                </Element>
                        
                <Element>
                    <StyledCaption>{ t("Description") }</StyledCaption>

                    <Inputs>
                        <Textarea value={ enItemDescription }
                            onChange={ (e) => setEnItemDescription(e.target.value) }
                            placeholder='English'
                        />

                        <Textarea value={ elItemDescription }
                            onChange={ (e) => setElItemDescription(e.target.value) }
                            placeholder='Ελληνικά'
                        />

                        <Textarea value={ ruItemDescription }
                            onChange={ (e) => setRuItemDescription(e.target.value) }
                            placeholder='Русский'
                        />
                    </Inputs>
                </Element>
            </Elements>

                <Block>
                    <Text bold>{ t("Vegetarian") }</Text>

                    <Toggle checked={ vegetarian }
                        onChange={ () => setVegetarian(!vegetarian) }
                    />
                </Block>

                <Block>
                    <Text bold>{ t("Vegan") }</Text>

                    <Toggle checked={ vegan }
                        onChange={ () => setVegan(!vegan) }
                    />
                </Block>
        </ModalFull>
    );
}

export default AddNewItem;

export const StyledCaption = styled(Caption)`
    width: 120px;
    margin-top: 0px;
`;

export const Elements = styled.div`
    padding: 0 16px;
`;

export const Element = styled.div`
    display: flex; 
    padding: 16px 0;
    border-bottom: 1px solid ${ ({ theme }) => theme.border };
`;

export const Inputs = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
