import React, { useState, useEffect, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

import  Button from '../../common/Button';
import { AuthContext } from '../../../Auth';
import { ReactComponent as MoreIcon} from '../../../img/pending.svg';
import AddNewItem from '../AddNewItem'
import Block from '../../common/Block';
import Body01 from '../../common/typography/Body01';
import db from '../../../config/firebase';
import Input from '../../common/Input';
import Items from '../items/Items';
import ModalBottom from '../../common/ModalBottom';
import ModalFull from '../../common/ModalFull';
import Body02 from '../../common/typography/Body02';
import Text from '../../common/Text';

import Headline06 from '../../common/typography/Headline06';

const Category = ({
    category,
    categories,
    items,
    invisible,
    userId,
    lang
}) => {
    const { t } = useTranslation();

    const theme = useTheme();

    const [ enCategoryName, setEnCategoryName ] = useState(category?.translated.en || '');
    const [ elCategoryName, setElCategoryName ] = useState(category?.translated.el || '');
    const [ ruCategoryName, setRuCategoryName ] = useState(category?.translated.ru || '');

    const [ open, setOpen ] = useState(false);
    const [ openEdit, setOpenEdit ] = useState(false);
    const [ openHide, setOpenHide ] = useState(false);
    const [ openDelete, setOpenDelete ] = useState(false);
    const [ openAddItem, setOpenAddItem ] = useState(false);
    const [ openEditPosition, setOpenEditPosition ] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const user = currentUser && currentUser.uid === userId;

    const filteredCategories = categories.filter(filteredCategory => filteredCategory.id !== category.id);

    const docRef = user && db.firestore()
    .collection('users')
    .doc(currentUser.uid);

    const changePosition = async (stamp) => {
        try {  
            const timestampCopy = new Date(stamp.toDate());
            timestampCopy.setSeconds(timestampCopy.getSeconds() - 1);
            const updatedTimestamp = firebase.firestore.Timestamp.fromDate(timestampCopy);
            
           const updatedCategories = categories.map(cat => {
                if (cat.id === category.id) {
                    return {
                        ...cat,
                        timestamp: updatedTimestamp
                    };
                }
                return cat;
            });
            
            await docRef.update({ 
                categories: updatedCategories,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });
            setOpenEditPosition(false);
        } catch (error) {
            alert('Error changing position of the category, please try again later or contact us');
        }
    };

    const updateCategory = async () => {
        try {   
            const updatedCategories = categories.map(cat => {
                if (cat.id === category.id) {
                    return {
                        ...cat,
                        translated: {
                            en: enCategoryName,
                            el: elCategoryName,
                            ru: ruCategoryName
                        }
                    };
                }
                return cat;
            });
    
            await docRef.update({ 
                categories: updatedCategories,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });
            setOpenEdit(false);
        } catch (error) {
            alert('Error updating category, please try again later or contact us');
        }
    };

    const hideCategory = async () => {
        try {   
            const updatedCategories = categories.map(cat => {
                if (cat.id === category.id) {
                    return {
                        ...cat,
                        hidden: !cat.hidden
                    };
                }
                return cat;
            });
            
            await docRef.update({ 
                categories: updatedCategories,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });
            setOpenHide(false);
        } catch (error) {
            alert('Error changing visibility of the category, please try again later or contact us');
        }
    };

    const deleteCategory = async () => {
        try {    
            const updatedItems = items.filter(item => item.category !== category.id);
            
            await docRef.update({
                categories: firebase.firestore.FieldValue.arrayRemove(category),
                items: updatedItems,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });

        } catch (error) {
            alert('Error removing category, please try again or contact us');
        }
        setOpenDelete(false);
    };


    const filteredItems = items.filter(item => item.category === category.id);

    useEffect(() => {
        if (openEdit) {
            setEnCategoryName(category.translated.en);
            setElCategoryName(category.translated.el);
            setRuCategoryName(category.translated.ru);
        }
    }, [ openEdit ]);

    if (category.hidden === true && currentUser?.uid !== userId) {
        return null;
    }
    return (
        <Root id={ category.id }>
            <>
                <CategoryName invisible={ invisible }
                    user={ user }
                >
                    <Headline06 bold 
                        ellipsis
                        color={ category.hidden ? theme.disabled : theme.text }>
                        { category?.translated ? category.translated[lang] : '' }
                    </Headline06>

                    { user &&
                        <StyledMoreIcon 
                            onClick={ () => setOpen(true) } 
                        />
                    }
                </CategoryName>

                { user && filteredItems.length === 0 && !invisible &&
                    <StyledText color={ theme.textSecondary }>
                        No items yet
                    </StyledText>
                }

                <Items items={ filteredItems }
                    hiddenCategory={ category.hidden }
                    userId={ userId }
                    lang={ lang }
                    categories={ categories }
                />
            </>

                <ModalBottom title={ t("Category") }
                    onClose={ () => setOpen(false) }
                    open={ open }
                >
                    <Block center
                        onClick={ () => (setOpenAddItem(true), setOpen(false)) }
                    >
                        <Text>{ t("Add item") }</Text>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenEdit(true), setOpen(false)) }
                    >
                        <Text>{ t("Edit") }</Text>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenEditPosition(true), setOpen(false))  }
                    >
                        <Text>{ t("Move category") } </Text>  
                    </Block>

                    <Block center
                        onClick={ () => (setOpenHide(true), setOpen(false)) }
                    >
                        <Text>{ category.hidden === true ? t("Make visible") : t("Hide") }</Text>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenDelete(true), setOpen(false)) }
                    >
                        <Text red>{ t("Delete") }</Text>
                    </Block>
                </ModalBottom>

           
                <ModalFull title={ t("Edit category") }
                    disabled={ !enCategoryName }
                    onClose={ () => setOpenEdit(false)  }
                    onSave={ updateCategory }
                    open={ openEdit }
                >
                    <EditCategory>
                         <Input id="enCategoryName" 
                            label='English'
                            value={ enCategoryName }
                            onChange={ (e) => setEnCategoryName(e.target.value) }
                            autoFocus
                            required
                        />

                        <Input id="elCategoryName"  
                            label='Ελληνικά'
                            value={ elCategoryName }
                            onChange={ (e) => setElCategoryName(e.target.value) }
                        />            

                        <Input id="ruCategoryName" 
                            label='Русский'
                            value={ ruCategoryName }
                            onChange={ (e) => setRuCategoryName(e.target.value) }
                        />
                    </EditCategory>
                </ModalFull>

                <ModalBottom onClose={ () => setOpenEditPosition(false) }
                    title={ t('Put before...') }
                    open={ openEditPosition }
                >
                { filteredCategories && filteredCategories.map(cat => (
                    <Block onClick={ () => changePosition(cat.timestamp) }
                        key={ cat.id }
                    >
                        <Body01>
                            {  cat?.translated ? cat.translated[lang] : '' }
                        </Body01>
                    </Block>
                ))}
            </ModalBottom>

                <ModalBottom onClose={ () => setOpenHide(false) }
                    open={ openHide }
                    title={ category.hidden === true ? t('Make category visible?') : t('Hide category?') }
                >
                    <Block center noBorder>
                        <Text small center>The category with all its items will be { category.hidden === true ? 'visible' : 'invisible'} to the customers</Text>
                    </Block>

                    <Block center noBorder>
                        <Button primary
                            label={ category.hidden === true ? t("Make visible") : t("Hide") }
                            onClick={ hideCategory }
                            width="100%"
                        />  
                    </Block>
                </ModalBottom>

                <ModalBottom onClose={ () => setOpenDelete(false) }
                    title={ t("Delete category?") }
                    open={ openDelete }
                >
                    <Block center noBorder>
                        <Text small center>The category with all its items will be deleted</Text>
                    </Block>

                    <Block center noBorder>
                        <DeleteButton label={ t("Delete") }
                            primary
                            onClick={ deleteCategory }
                            width="100%"
                        />
                    </Block>
                </ModalBottom>

                <AddNewItem fixedCategory={ category }
                    onClose={ () => setOpenAddItem(false) }
                    categories={ categories }
                    open={ openAddItem }
                    lang={ lang }
                />
        </Root>
    );
}

export default Category;

const DeleteButton = styled(Button)`
    background-color: ${ ({ theme }) => theme.red }
`;

const Root = styled.div`
    margin: 0 0 16px;

`;
const EditCategory = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px;
`;

const CategoryName = styled.div`
    align-items: center;
    display: ${ ({ invisible }) => invisible ? 'none' : 'flex' };
    justify-content: space-between;
    padding: 16px 20px 0;
`;

const StyledText = styled(Body02)`
    padding: 16px 24px;
`;

const StyledMoreIcon = styled(MoreIcon)`
    flex-shrink: 0;
`;  