import React from 'react';
import styled from 'styled-components';
import Body02 from './typography/Body02';

const Tooltip = ({ 
    text,
    isOpen,
    onClose 
}) => {
    if (!isOpen) return null;

    return (
        <Root onClick={onClose}>
                <Body02>
                    {text}
                </Body02>
        </Root>
    );
};

export default Tooltip;

const Root = styled.div`
    position: absolute;
    z-index: 1000;
    top: -44px;
    left: 0;
    background: ${({ theme }) => theme.body};
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: auto;
    animation: fadeIn 0.2s ease-out;
    white-space: nowrap;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(8px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`; 