import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { AuthContext } from '../../../Auth';
import Share from './Share';
import Languages from './Languages';
import Headline06 from '../../common/typography/Headline06';

const Header = ({
    activeLanguage,
    businessName,
    languages,
    languagesOpen,
    onLanguagesClose,
    onLanguagesOpen,
    logo,
    userId
}) => {
    const { currentUser } = useContext(AuthContext);
    const user = currentUser?.uid === userId;

    const [visible, setVisible] = useState(false);

    const listenToScroll = () => {
        let heightToShowFrom = 250;
        const winScroll = document.body.scrollTop || 
            document.documentElement.scrollTop;
           
        if (winScroll > heightToShowFrom) { 
            !visible &&      // to limit setting state only the first time         
            setVisible(true);
        } else {
            setVisible(false);
        }  
      };

    useEffect(() => {   
        if (!logo) {
            window.addEventListener("scroll", listenToScroll);
            return () => 
               window.removeEventListener("scroll", listenToScroll)
            ; 
        }
      }, [])

    return (
        <Root user={ user }>
            <StyledLanguages activeLanguage={ activeLanguage }
                languages={ languages }
                open={ languagesOpen }
                onOpen={ onLanguagesOpen }
                onClose={ onLanguagesClose }
            />

            { (visible || !logo) && <Headline06 bold ellipsis>{ businessName }</Headline06> }

            <StyledShare />
        </Root>
    );
}

export default Header;

const Root  = styled.div`
    align-items: center;
    background: ${ ({ theme }) => theme.body };
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 64px;
    top: ${({ user }) => user ? '64px' : 0 };
    z-index: 2;
`;

const StyledLanguages = styled(Languages)`
    position: absolute;
    left:16px;
`;

const StyledShare = styled(Share)`
    position: absolute;
    right:16px;
`;

const ShareDiv = styled.a`
    position: absolute;
    right:16px;
    border: 1px solid ${({ theme }) => theme.border };
    width: 48px;
    height: 48px; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;