import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: []
}

const itemsSlice = createSlice({
    name: "items",
    initialState,
    reducers: {
        receivedItems(state, action) {
            const items = action.payload;
            const newItems = items.map(item => ({ ...item }))
            state.items = newItems;
        }
    }
})

export default itemsSlice.reducer;

export const { receivedItems } = itemsSlice.actions;