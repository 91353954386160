import { withRouter } from 'react-router';
import db from '../config/firebase';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as CancelIcon } from '../img/close.svg';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import Title from '../components/common/Title';
import Text from '../components/common/Text';
import Icon from '../components/common/Icon';
import { landingRoute } from '../helpers/routes';
import { useHistory } from 'react-router-dom';

const SignUp = () => {
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ displayName, setDisplayName ] = useState('');

    const history = useHistory();

	const handleSignUp = async event => {
            event.preventDefault();
            setLoading(true);

			const { email, password } = event.target.elements;

			try {
				const { user } = await db
				.auth()
				.createUserWithEmailAndPassword(email.value, password.value);
                
                if (user) {
                    const uid = db.auth().currentUser.uid;
                    const userDocRef = db.firestore().collection('users').doc(uid);
    
                    userDocRef
                    .set({
                        displayName: displayName
                    })
                    
				    history.push(`/${ user.uid }`)
			    }
            } catch (error) {
				setLoading(false);
                setError(error.message);
			}
};

    return (
        <>
        <Root>
            <a href={ landingRoute }><CloseIcon icon={ <CancelIcon /> }/></a>

            <StyledTitle bold>
                Create Your Free Account
            </StyledTitle>
            <StyledSubtitle gray small>No credit card required </StyledSubtitle>

            <Form onSubmit={ handleSignUp }>
                <StyledInput autoFocus
                    name="displayName"
                    type="text"
                    placeholder="Business name"
                    primary
                    value={ displayName }
                    onChange={ (e) => setDisplayName(e.target.value) }
                />

                <StyledInput name="email"
                    type="email"
                    placeholder="Email"
                    primary
                />

                <StyledInput name="password"
                    type="password"
                    placeholder="Password"
                    primary
                />

                <Error small red>{ error }</Error>

                <Button label={ loading ? "Creating..." : "Create account" }
                    type="submit"
                    primary
                    loading={ loading }
                    width="100%"
                />
            </Form>

            <StyledText grey>Already has account? <StyledLink to="/signin">Login</StyledLink> to continue</StyledText>
        </Root>
        </>
    );
};

export default withRouter(SignUp);

const CloseIcon  = styled(Icon)`
    position: fixed;
    right: 0;
    top: 0;
`;

const Error = styled(Text)`
    margin-bottom: 12px;
    text-align: center;
`;

const Form = styled.form`width: 100%;`;

const StyledLink = styled(Link)`
    color: ${ ({ theme }) => theme.text };
    text-decoration: underline;
    font-family: bold;
`;

const StyledInput = styled(Input)`
    margin-bottom: 16px;
`;

const StyledText = styled(Text)`
    margin-top: 24px;
    text-align: center;
    width: 100%;
`;

const StyledSubtitle = styled(Text)`
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
`;

const StyledTitle = styled(Title)`
    margin-bottom: 16px;
`;

const Root = styled.div`
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        justify-content: flex-start;
        margin: 80px auto;
        width: 80%;
        padding-bottom: 80px;
    }
`;
