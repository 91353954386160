import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ClearIcon } from '../../img/close.svg'; // Adjust the import based on your setup

const Input = React.forwardRef(({
    autoFocus,
    center,
    className,
    hidden,
    icon,
    id,
    label,
    name,
    onChange,
    placeholder,
    required,
    type = 'text',
    value,
    width,
    onClear,
    clearable
}, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (e) => {
        if (onChange) { 
            onChange(e); 
        };
        setIsFocused(!!e.target.value); 
    };
    
    return (
    <InputContainer>
        { label &&            
            <Label isFocused={isFocused || value}
                htmlFor={ id }
            >
                { label } {required && <span>*</span>}
            </Label> 
            }
        
        <InputWrapper>
            <StyledInput
               id={ id }
               autoFocus={ autoFocus }
               className={ className }
               center={ center }
               grey={ hidden }
               name={ name }
               onChange={ handleChange }
               onFocus={() => setIsFocused(true)}
               onBlur={() => setIsFocused(!!value)}
               type={ type }
               value={ value }
               ref={ ref }
               width={ width }
               placeholder={ isFocused ? placeholder : '' }
               maxLength={ 120 }
            />
                
            { clearable && value && <ClearButton onClick={() => { 
                    onClear(); 
                    setIsFocused(false);
                }}><ClearIcon /></ClearButton>} 
        </InputWrapper>
    </InputContainer>
 );
});

export default Input;

const StyledInput = styled.input`
    -webkit-appearance: none;
    background: ${ ({ theme }) => theme.body };
    border-radius: 8px;
    border: ${ ({ theme }) => `1px solid ${ theme.border }`};
    color: ${ ({ theme }) => theme.text };
    font-family: regular, Arial;
    font-size: 18px;
    height: 56px;
    outline: none;
    padding: 0 32px 0 12px;
    text-align: ${ ({ center }) => center && 'center'};
    width: ${ ({ width }) => width ? width : '100%'};

    ::placeholder {
        color: ${ ({ theme }) => theme.placeholder };
    }

     &:focus {
        border: ${ ({ theme }) => `2px solid ${ theme.text }`};
    }
`;

const InputContainer = styled.div`
    position: relative; 
    width: 100%;
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`;


const ClearButton = styled.div`
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    display: flex; 
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    width: 32px;

    svg {
        width:20px;
        height: 20px;
        fill: ${ ({ theme }) => theme.textSecondary };
    }
`;

const Label = styled.label`
    background: ${ props => props.theme.body };
    color: ${({ theme }) => theme.textSecondary };
    font-size: ${({ isFocused }) => (isFocused ? '12px' : '18px')};
    left: 12px;
    padding: 0 2px;
    position: absolute;
    top: ${({ isFocused }) => (isFocused ? '2px' : '50%')}; 
    transform: translateY(-50%); 
    transition: all 0.2s ease;
`;
