import React from 'react';
import styled from 'styled-components';

import Body02 from './typography/Body02';
import { landingRoute } from '../../helpers/routes';

const Footer = () => {
    return (
        <Root>
            <StyledBody02 small>
                Are you a store owner?
            </StyledBody02>
            
            <StyledBody02 small>
                Check about 
                <Link href={ landingRoute } target="_blank">
                    reaction.menu
                </Link>
                { ` or` }
                <Link href="/signin">
                    Login
                </Link>
            </StyledBody02>
        </Root>
    );
}

export default Footer;

const Root  = styled.div`
    align-items: center;
    background-color: ${ ({ theme }) => theme.body };
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 168px;
    justify-content: center;
    text-align: center;
    width: 100%;
    position: absolute;
`;

const Link  = styled.a`
    margin-left: 4px;
    font-family: extraBold;
    color: ${ ({ theme }) => theme.text };
    text-decoration: underline;
`;

const StyledBody02  = styled(Body02)`
    white-space: nowrap;
    text-align: center;
    margin-bottom: 4px;
    color: ${ ({ theme }) => theme.textSecondary };

    span {
        font-family: extraBold;
        color: ${ ({ theme }) => theme.text };
    }
`;
