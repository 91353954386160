import { useTranslation } from 'react-i18next';
import React from "react";
import Resizer from "react-image-file-resizer";
import styled, { useTheme } from 'styled-components';

import { ReactComponent as CameraIcon } from '../../img/camera.svg';

import Block from './Block';
import Button from './Button';
import LoadingSpinner from './Loadings/LoadingSpinner';

export const resizeImage = (image) =>
new Promise((resolve) => {
    Resizer.imageFileResizer(
        image,
        800,
        800,
        "JPEG",
        100,
        0,
        (uri) => {
            resolve(uri);
        },
        "file"
    );
});

const ImageUpload = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { 
        image, 
        deleteLabel=t("Delete photo"),
        onImageChange, 
        onDelete, 
        inProgress,
     } = props;

    if (image) {
        return (
            <Root>
                <Image>
                    <img src={ typeof image === 'object' ? URL.createObjectURL(image) : image }
				        alt="Reaction menu"
                    /> 
                </Image>

                <Block center noBorder>
                    <Button label={ deleteLabel }
                        onClick={ onDelete }
                        red
                    />
                </Block>
            </Root>
        )
    }

    if (inProgress) {
        return (
            <Block>
            <Label>
               <LoadingSpinner color={ theme.text } 
                size='32px'
               />
            </Label> 
            </Block>
        )
    }

    return (
        <Block noBorder>
        <Label>
            <input type="file" onChange={ onImageChange } 
                accept="image/x-png, image/jpeg, .svg"
            />
            
            <StyledCameraIcon />
        </Label> 
        </Block>
    )
}

export default ImageUpload;

const StyledCameraIcon = styled(CameraIcon)`
    width: 32px;

    circle {
        fill: ${ ({ theme }) => theme.text };
    }

    path {
        fill: ${ ({ theme }) => theme.text };

        &:first-of-type {
            fill: transparent;
        }
    }
`;


const Image = styled.div`
    min-height: 120px;
    padding: 24px;

    img {
        display: block; 
        border-radius: 4px;
    }
`;

const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    align-items: center;
    border: 1px dashed ${ ({ theme }) => theme.text };
    border-radius: 4px;
    display: flex;
    height: 120px;
    justify-content: center;
    width: 100%;

    input[type="file"] {
        display: none;
    }
`;