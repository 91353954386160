import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';

import { AuthContext } from '../Auth';
import { receivedCategories } from '../components/features/categories/categoriesSlice';
import { receivedUserSettings } from '../components/features/settings/userSettingsSlice';
import { receivedItems } from '../components/features/items/itemsSlice';
import Cart from '../components/features/cart/Cart'; 
import Categories from '../components/features/categories/Categories';
import db from '../config/firebase';
import Footer from '../components/common/Footer';
import Header from '../components/features/header/Header';
import HeaderOfUser from '../components/features/HeaderOfUser';
import Navigation from '../components/features/Navigation';
import Skeleton from '../components/common/Loadings/Skeleton'; 
import Socials from '../components/features/Socials'; 
import Story from '../components/features/Story/Story'; 

const Tenant = ({
}) => {
	firebase.firestore.setLogLevel('debug');

	const initialLanguageActive = window.localStorage.getItem('activeLanguage') || 'en';

    const dispatch = useDispatch();

	const items = useSelector(state => state.items.items);

	const [ loading, setLoading ] = useState(true);
	const [ businessName, setBusinessName ] = useState('');
	const [ logo, setLogo ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ activeLanguage, setActiveLanguage ] = useState(initialLanguageActive);
	const [ languagesOpen, setLanguagesOpen ] = useState(false);
	const [ wifiPassword, setWifiPassword ] = useState('');

	const { i18n } = useTranslation();

	const changeLanguage = lng => {
        i18n.changeLanguage(lng);

        setActiveLanguage(lng);
    };

    const languages = [ {
        name: 'en',
        action: () => { changeLanguage('en'); setLanguagesOpen(false) }
    }, {
        name: 'el',
        action: () => { changeLanguage('el'); setLanguagesOpen(false) }
    }, {
		name: 'ru',
		action: () => { changeLanguage('ru'); setLanguagesOpen(false) }
	} ];

    useEffect(() => {
        window.localStorage.setItem('activeLanguage', activeLanguage)
    }, [activeLanguage]);

	const { currentUser } = useContext(AuthContext);

	let location = useLocation();
	const url = location.pathname;
	const idFromUrl = url?.slice(1);

	const docRef = db.firestore().collection("users").doc(idFromUrl);

/*	const getSubscription = async () => {
    	await docRef
  		.collection('subscriptions')
  		.where('status', 'in', ['trialing', 'active'])
  		.onSnapshot(async (snapshot) => {
    	// In this implementation we only expect one active or trialing subscription to exist.
    		const doc = snapshot.docs[0];
    		setSubscriptionExist(doc?.id);
  		});
	};
*/
	const getData = async () => {
		await docRef.onSnapshot(doc => {
			const data = doc.data();
			if (!data) return;
			setLoading(false);
			
			// Update basic info
			setBusinessName(data?.displayName || '');
			setLogo(data?.logoUrl || '');
			setPhone(data?.phone || '');
			
			dispatch(receivedUserSettings(data))

			const categories = (data?.categories || []).map(cat => ({
				hidden: cat.hidden,
				id: cat.id,
				translated: cat.translated,
				timestamp: cat.timestamp
			}))
			.sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0));
			dispatch(receivedCategories(categories));
			
			const items = (data?.items || []).map(item => ({
				id: item.id,
				category: item.category, 
				hidden: item.hidden,
				imageUrl: item.imageUrl,
				price: item.price, 
				timestamp: item.timestamp,
				translated: item.translated,
				vegan: item.vegan,
				vegetarian: item.vegetarian
			}))
			.sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0));
			dispatch(receivedItems(items));

			setWifiPassword(data?.wifiPassword || '');
		}, (error) => {
			console.log(error);
			setLoading(false);
		});
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Root user={ currentUser && currentUser.uid === idFromUrl }>

			<Wrap>
				<HeaderOfUser userId={ idFromUrl }
					businessName={ businessName }
					logo={ logo }
					lang={ activeLanguage }
					phone={ phone }
					wifiPassword={wifiPassword}
				/>

				<Header businessName={ businessName } 
					activeLanguage={ activeLanguage }
					languages={ languages }
					languagesOpen={ languagesOpen }
					logo={ logo }
					onLanguagesClose={ () => setLanguagesOpen(false) }
					onLanguagesOpen={ () => setLanguagesOpen(true) }
					userId={ idFromUrl }
				/>

				<LimitWidth>
					{ logo && 
						<Logo>
							<img src={ logo } alt="reaction.menu"/> 
						</Logo> 
					}

					<Socials />
				
					<Story />

					{ loading ? <Skeleton /> :
					<>
						<Navigation lang={ activeLanguage }
							logo={ logo }
							userId={ idFromUrl }
						/>

						<Categories items={ items }
							userId={ idFromUrl }
							lang={ activeLanguage }
						/>
					</>
					}
				</LimitWidth>
			</Wrap>
			<Cart lang={ activeLanguage } />

			<Footer />
		</Root>
    );
}

export default Tenant;

const Root = styled.div`
	padding-top: ${ ({ user }) => user ? '64px' : 0 };
	position: relative;
	min-height: 100vh;
	background-color: ${ ({ theme }) => theme.body };
`;

const LimitWidth = styled.div`
	max-width: 600px;
	margin: 0 auto;
`;
const Wrap = styled.div`
	padding-bottom: 168px;
`;

const Logo  = styled.div`
	margin: 32px auto 24px;
	z-index: 2;
    height: auto;
    position: relative;
    width: calc(100% - 80px - 80px);
`;