import React from 'react';
import styled, { useTheme } from 'styled-components';

import { ReactComponent as VeganIcon } from '../../img/vegan.svg';

import Body02 from './typography/Body02';

const Tag = ({
    className,
    label
}) => {
    const theme = useTheme();

    return (
        <Root className={ className }>
            <StyledVeganIcon />
            <Body02 color={ theme.textSecondary }>
                { label }
            </Body02>
        </Root>
    );
}

export default Tag;

const Root = styled.div`
    align-items: flex-top;
    display: flex;
    padding: 0;
    margin-right: 8px;
`;

const StyledVeganIcon = styled(VeganIcon)`
    margin-right: 4px;
    width: 26px;
    height: 26px;
    fill: ${ ({ theme }) => theme.green };

`;